@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.App {
  text-align: center;
}

.nav-link {
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-right: 10px;
  padding: 5px;
  font-family: "montserrat";
  margin-top: 35px;
}

.level5 {
  border-radius: 5px;
  border: 2px solid #652d90;
}

.checkbox {
  appearance: none;
  border: 2px solid #652d90;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.Moment {
  font-family: Roboto;
  font-size: 12px;
}
